<template>
	<div>
		<div class="qkxx_title">
			初审稿件
		</div>
		<div v-if="orderlistlength==0" class="nodata">
						暂无数据
		</div>
		<div v-else>
			<div class="qkcont">
			<order :orderlist="orderlist"></order>
			</div>
			<div class="ygdPage">
				<el-pagination class="fy" small @size-change="handleSizeChange" :page-size="pagesize" :page-sizes="[10,20,30]" layout="total, sizes, prev, pager, next, jumper" @current-change="current_change" :total="total" background>
				</el-pagination>
			</div>
		</div>
		
	</div>
</template>

<script>
	import order from '../components/order'
	import {listdata} from "../../../API/QKD/list.js"
	export default {
		components: {
			order
		},
		data() {
			return {
				path: "/Periodical/firstcheck",
				now:4,
				childpath: "/Periodical/check/detail",
				total: 0, //默认数据总数
				pagesize: 10, //每页的数据条数
				currentPage: 1, //默认开始页面
				orderlist: [ //订单列表数据
					
				],
				orderlistlength:0, //订单列表长度
				trace: [ //跟踪数据

				],
				article: {}
			}
		},
		methods: {
			// 分页
			current_change: function(currentPage) {
				this.currentPage = currentPage;
				this.checkdata()
			},
			handleSizeChange(val) {
				console.log(val);
				this.pagesize = val;
				this.checkdata()
			},
			//查询列表数据
			checkdata(){
				listdata(localStorage.getItem('QKId'),this.currentPage,this.pagesize,0).then(res=>{
					console.log(res)
					this.orderlist=res.data.data;
					this.orderlistlength=this.orderlist.length
					this.total=res.data.total
				})
			}
		},mounted(){
			this.checkdata()
		}
		

	}
</script>

<style>
	.qkcont {
		padding: 10px;
	}
	.nodata{
		text-align: center;
		line-height:80px;
		color: #e6e6e6;
	}
</style>